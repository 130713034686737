// Here you can add other styles
.header {
    min-height: calc(4rem + 1px); // stylelint-disable-line function-disallowed-list
}

.sidebar-brand-full {
    margin-left: 3px;
}

.sidebar-header {
    .nav-underline-border {
        --cui-nav-underline-border-link-padding-x: 1rem;
        --cui-nav-underline-border-gap: 0;
    }

    .nav-link {
        display: flex;
        align-items: center;
        min-height: calc(4rem + 1px); // stylelint-disable-line function-disallowed-list
    }
}

.sidebar-toggler {
    @include ltr-rtl('margin-left', auto);
}

.sidebar-narrow,
.sidebar-narrow-unfoldable:not(:hover) {
    .sidebar-toggler {
        display: none;
    }
}

.card,
.dropdown-menu,
.footer,
.sidebar {
    box-shadow:
        rgb(50 50 93 / 2%) 0 2px 5px -1px,
        rgb(0 0 0 / 5%) 0 1px 3px -1px;
}

.sidebar-nav .nav-link:hover {
    --cui-sidebar-nav-link-hover-bg: rgba(255, 255, 255, 0.0375);
}

.sidebar-nav .nav-link.active {
    --cui-sidebar-nav-link-active-bg: rgba(255, 255, 255, 0.0375);
}

.h-8 {
    height: 2rem;
}

.h-12 {
    height: 3rem;
}

.h-24 {
    height: 6rem;
}
